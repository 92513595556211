var pawLibrary = {
	monthNames: ["Jan","Feb","Mar","Apr","May","June","Jul","Aug","Sept","Oct","Nov","Dec"],
	map: false,
	formTitle: "",
	dropdownMenus: function()
	{
		$('.parent > a').on('click', function(e) {
			var selected = $(this);
			var id = selected.attr('id');
			if(id != '') id = id.replace('showMenu','');

			if($('#subMenu' + id).length == 1)
			{
				e.preventDefault();
			}

			// Show
			if(selected.hasClass('highlight') == true) {
				// Hide
				selected.removeClass('highlight');
				$('.parent-sub').hide();
			} else {
				// Show
				selected.addClass('highlight');
				$('.parent-sub').hide();
				$('.parent a').removeClass('highlight');
				$('#subMenu' + id).fadeIn();
				$('#showMenu' + id).addClass('highlight');
			}
		});
	},
	search: function(term)
	{
		$('#searchresults-wrap').html('<div id="loading">Searching...</div>').load('ajax/search.inc.php?term=' + term);
	},
	validateForm: function()
	{
		var errors = 0;

		var name_txt = $('#name_txt');
		var email_txt = $('#email_txt');
		var message_txt = $('#message_txt');
		var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

		if(name_txt.val().length < 3)
		{
			name_txt.addClass('required_border');
			errors++;
		} else {
			name_txt.removeClass('required_border');
		}

		if( !filter.test(email_txt.val()) )
		{
			email_txt.addClass('required_border');
			errors++;
		} else {
			email_txt.removeClass('required_border');
		}

		if(message_txt.val().length < 3)
		{
			message_txt.addClass('required_border');
			errors++;
		} else {
			message_txt.removeClass('required_border');
		}

		return (errors == 0) ? true : false;

	},

	getTwitterProfilePic: function(elemId, username)
	{

		$.ajax({
			type: 'json',
		    url: '../ajax/getTwitter.inc.php?username=' + username,
		    beforeSend: function() {
		    	$('#comment' + elemId + ' .img-wrap').html('<img src="./imgs/loader.gif" alt="'+username+'" class="loader" />');
		    },
		    success: function(data){
		    	var img = data[0].image;
		    	$('#comment' + elemId + ' .img-wrap').html('<img src="'+img+'" alt="'+username+'" class="profile" />');
		    }
		});

	},

	generateMap: function(elem,lat,lng,zoomLevel) {
		var myLatlng = new google.maps.LatLng(lat,lng);
		var myOptions = {
		  zoom: zoomLevel,
		  center: myLatlng,
      scrollwheel: false,
		  mapTypeId: google.maps.MapTypeId.ROADMAP,
		}

		this.map = new google.maps.Map(document.getElementById(elem), myOptions);
	},

	addMarker: function(lat, lng, location_title, location_add) {
		var myLatlng = new google.maps.LatLng(lat,lng);
		var contentString = '<div id="mapContent">'+
			'<p><strong>'+location_title+'</strong></p>'+
				'<p>'+location_add+'</p>'+
				'<a class="btn" href="http://maps.google.co.uk/maps?daddr='+encodeURI(location_add)+'">Get Directions</a>' +
			'</div>';

		var infowindow = new google.maps.InfoWindow({
			content: contentString
		});

		var marker = new google.maps.Marker({
			position: myLatlng,
			map: this.map,
			title: location_title
		});

		google.maps.event.addListener(marker, 'click', function() {
		  infowindow.open(this.map,marker);
		});

		google.maps.event.addDomListener(window, 'resize', function() {
			//var center = map.getCenter();
    	//map.setCenter(center);
		});
	},
	centerMap: function() {
		var center = this.map.getCenter();
		console.log('CENTER  = ', center);
		this.map.setCenter();
	},

	setMap : function(elem, lat, lng, location_title, location_add, drag) {
		var myLatlng = new google.maps.LatLng(lat,lng);
		var myOptions = {
		  zoom: 14,
		  center: myLatlng,
      scrollwheel: false,
		  mapTypeId: google.maps.MapTypeId.ROADMAP,
		  draggable: drag
		}

		var map = new google.maps.Map(document.getElementById(elem), myOptions);

		var contentString = '<div id="mapContent">'+
			'<p><strong>'+location_title+'</strong></p>'+
				'<p>'+location_add+'</p>'+
				'<a class="btn" href="http://maps.google.co.uk/maps?daddr='+encodeURI(location_add)+'">Get Directions</a>' +
			'</div>';

		var infowindow = new google.maps.InfoWindow({
			content: contentString
		});

		var marker = new google.maps.Marker({
			position: myLatlng,
			map: map,
			title: location_title,
			draggable: drag
		});

		if(drag == true) {
			google.maps.event.addListener(marker, 'dragend', function() {
					var point = marker.position;
					if(lat_txt) lat_txt.value = point.$a;
					if(long_txt) long_txt.value = point.ab;

				});
		}

		google.maps.event.addListener(marker, 'click', function() {
		  infowindow.open(map,marker);
		});

		google.maps.event.addDomListener(window, 'resize', function() {
			//var center = map.getCenter();
    	//map.setCenter(center);
		});

	},

	setMapByGeocode : function(elemId, address, zoomlevel) {

		var mapOptions = {
		  zoom: zoomlevel,
          scrollwheel: false,
		  mapTypeId: google.maps.MapTypeId.ROADMAP,
		  draggable: false
		}

		var contentString = '<div id="mapContent">'+
				'<p>'+address+'</p>'+
				'<a href="http://maps.google.co.uk/maps?daddr='+encodeURI(address)+'">Get Directions</a> &raquo;' +
			'</div>';

		var infowindow = new google.maps.InfoWindow({
            content: contentString
         });

		var geocoder = new google.maps.Geocoder();
		var map = new google.maps.Map(document.getElementById(elemId), mapOptions);

		geocoder.geocode( {
			'address': address
				}, function(results, status) {
					  if (status == google.maps.GeocoderStatus.OK) {
						map.setCenter(results[0].geometry.location);
						var marker = new google.maps.Marker({
							map: map,
							position: results[0].geometry.location
						});

						google.maps.event.addListener(marker, 'click', function() {
						  infowindow.open(map,marker);
						});

					  }
					}
		);

	},

	mobileNav: function()
	{
		var mobileNavItems = $('#mobile-nav-items');
		var mobileBtn = $('#mobile-burger-btn');
		var pageBody = $('#page-wrap-inner');
		var secondMenu = mobileNavItems.find('ul.second');
		if( mobileBtn.length > 0 )
		{
			// Load Menu
			mobileBtn.click(function() {
				if(mobileBtn.hasClass('menu-open') == true) {
					pageBody.removeClass('push');
					mobileBtn.removeClass('menu-open');
				} else {
					pageBody.addClass('push');
					mobileBtn.addClass('menu-open');
				}
			});

			$('.triggerSubMenu').unbind().click(function(e) {
				var clicked = e.currentTarget.id.replace("showMenu","");
				console.log('CLCIEKD== ', clicked);
				var target = mobileNavItems.find('#subMenu' + clicked);
				var status = target.hasClass("open");

				if(status == false)
				{
					secondMenu.slideUp(function() {
						secondMenu.removeClass('open');
					});

					target.slideDown(function() {
						target.addClass('open');
					});
				} else {
					target.slideUp(function() {
						target.removeClass('open');
					});
				}
			});
		}

		$('#mobile-nav-inner-items').hcSticky({
			bottom:0
		});
	},

	dateSelector: function() {
		if($('.datepicker').length > 0)
		{
			var d = new Date();
			var today = d.getDate() + " " + this.monthNames[d.getMonth()] + " " + d.getFullYear().toString().substr(2,2);
			$(".datepicker").minical({
				trigger: '.datepicker',
				from: new Date(),
				initialize_with_date: false
			}).val('Starting ' + today);
		}
	},

	navigationAutocompleteResults: function(direction) {
		var target = $('#search-result ul');
		var index = target.find('li.highlight');

		if(index.length == 0) {
			pawLibrary.highlight = 1;
			target.find('li:nth-child(1)').addClass('highlight');
		} else {
			pawLibrary.highlight = (direction == 40) ? pawLibrary.highlight + 1 : pawLibrary.highlight - 1;
			$('#search-result ul li').removeClass('highlight');
			if(pawLibrary.highlight == 0) {
				pawLibrary.highlight = 0;
			} else {
				target.find('li:nth-child(' + (pawLibrary.highlight) + ')').addClass('highlight');
			}
		}

		//target.find('ul li:nth-child(' + (index + 1) + ')').addClass('highlight');
	},

	productAutocompleteSearch: function(term, event) {
		var target = $('#search-result');
		var searchFrm = $('#search_frm');

		searchFrm.submit(function (e) {
			e.preventDefault();
		});

		if((event.keyCode == 38) || (event.keyCode == 40)) {
			// NAVIGATE
			pawLibrary.navigationAutocompleteResults(event.keyCode);
		} else if(event.keyCode == 13) {
			// REDIRECT TO A PRODUCT PAGE IF ONE IS SELECTED.  OTHERWISE REDIRECT TO SEARCH RESULT
			var url = "";
			var target = $('#search-result ul li.highlight');
			var base = $('base').attr('href');
			if(target.length == 1) {
				url = target.find('a').attr('href').replace("./", "");
			} else {
				url = searchFrm.attr('action').replace("./", "");
				url += "?search_txt=" + encodeURI(term);
			}
			window.location.href = base + url;
		} else {
			// IF TERM IS LONGER THAN 3 LETTERS .. SCAN DATATABLE
			if(term.length > 3) {
				$.ajax({
					url: './ajax/products.inc.php?init=products&search=' + term
				}).done(function(response) {
					var data = $.parseJSON(response);
					if(data.length == 0) {
						target.empty();
					} else {
						var results = target.find('ul');
						if(results.length == 0) {
							target.append('<ul></ul>');
						}
	
						target.find('ul').empty();
	
						$.each(data, function(i, v) {
							var tmp = '<li>';
										tmp += '<a href="' + v.url + '">';
											tmp += '<span class="img-wrap">';
												tmp += '<img src="' + v.thumb.filename + '" alt="' + v.title + '" />';
											tmp += '</span>';
											tmp += '<span class="txt-wrap">' + v.title + '</span>';
										tmp += '</a>';
									tmp += '</li>';
							target.find('ul').append(tmp);
						});
					}
				});
			} else {
				target.empty();
			}
		}

	},

	inlineEnquiries: function()
	{
		var enquiry_btn = $('#enquiry_btn');

		enquiry_btn.click(function(e) {
			e.preventDefault();

			enquiry_btn.fancybox({
					fitToView	: true,
					autoSize	: true,
					type: 'ajax',
					padding: 0,
					maxWidth: 530,
					width: "90%",
					href: 'frontend/view/inc/forms/enquiry.inc.php',
					beforeShow: function() {
						var form = $('#enquiry form');

						var title = $('.productDetails h1').html();
						var days = $('#day_sel').val();
						var date = $('#date_txt').val();
						if(days > 0) {
							title += " for ";
							title += (days == 1) ? '1 Day' : days + ' Days';
						}
						if(date != '') {
							title += ' ' + date;
						}
						pawLibrary.formTitle = title;
						$('#enquiry h3').html("Enquire About " + pawLibrary.formTitle);
        		$("body").css({'overflow-y':'hidden'});
					},
					afterClose: function() {
        		$("body").css({'overflow-y':'visible'});
					},
					afterShow: function() {
						var form = $('#enquiry form');
						var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
						var valid = true;
						var input;

						form.submit(function(e) {
							e.preventDefault();
							valid = true;

							if(valid == true)
							{
								input = $('#name_txt');
								if(input.val() == '') {
									input.addClass('required');
									valid = false;
								} else {
									input.removeClass('required');
								}
							}

							if(valid == true)
							{
								input = $('#email_txt');
								if(re.test(input.val()) == false) {
									input.addClass('required');
									valid = false;
								} else {
									input.removeClass('required');
								}
							}

							if(valid == true)
							{
								input = $('#message_txt');
								if(input.val() == '') {
									input.addClass('required');
									valid = false;
								} else{
									input.removeClass('required');
								}
							}

							if(valid == true)
							{
								var loader = $('#loader');
								var data = {};
									data['name_txt'] = $('#name_txt').val();
									data['email_txt'] = $('#email_txt').val();
									data['branch_txt'] = $('#branch_txt').val();
									data['telephone_txt'] = $('#telephone_txt').val();
									data['message_txt'] = $('#message_txt').val();
									data['pageName'] = pawLibrary.formTitle;
									data['list_txt'] = "";
									data['url'] = 'http://' + window.location.hostname + window.location.pathname;

								form.hide();
								loader.fadeIn();

								$.ajax({
									type: 'POST',
									url: './ajax/enquiryFormSend.inc.php',
									data: data,
								}).done(function(response) {
									loader.html(response);
								});
							}

							return false;
						});
					}
				});


		});
	},

	onResize: function()
	{

	},

	init: function()
	{
		pawLibrary.mobileNav();
		pawLibrary.dropdownMenus();
		pawLibrary.dateSelector();
		pawLibrary.inlineEnquiries();
		
		if($('#search_txt').length == 1) {
			$('#search_txt').on('keyup', function(e) {
				e.preventDefault();
				pawLibrary.productAutocompleteSearch(this.value, e);
			});
		}

		$('#submenu_sel').on('change', function() {
			var url = $(this).val();
			window.location = url;
		});
	}

}

pawLibrary.init();
